import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const CrikensColosseum = ({data}) => (
 <div> 

    <SEO 
    title="Criken's Colosseum"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Phil Murphy">
        </meta>

        <meta name="og:description" content="Phil Murphy">
        </meta>

        <meta name="description" content="Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>



    


    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>


        <p className="EssayTitlePage">Criken's Colosseum</p>

        <div className="P_style">

        <p><b>Criken’s Colosseum</b> is an audience interactive roleplaying show in ARK where streamers are the heirs of a recently fallen king. They complete quests for honor/prestige and take on side missions for local town factions which evolve the world between episodes based upon their success and choices.</p> 

        <p>To make it possible, we designed a unique interactive Twitch extension where viewers can nominate and vote for various actions to occur, wager on their favorite streamers and earn free currency.</p>

        <p>The show airs on <a href="https://www.twitch.tv/criken" target="_blank" rel="noopener"><b>twitch.tv/criken</b></a></p>

            <div className="EmbedVideo">
                    <iframe src="https://www.youtube.com/embed/S3bU5UwRBM0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
            </div>

            <div className="EmbedVideo">
                    <iframe src="https://www.youtube.com/embed/XpU-4U_sDJo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
            </div>

            <p><h2>UI Components:</h2></p>

            <div className="EssayImagePage2">
          
            <Img 
                fluid={data.ColosseumUI_Part1.childImageSharp.fluid}
            />

            </div>

            <div className="EssayImagePage2">
            
            <Img 
                fluid={data.ColosseumUI_Part2.childImageSharp.fluid}
            />
            
            </div>
 



        </div>


        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default CrikensColosseum

export const query = graphql`
  query {
  
    ColosseumUI_Part1: file(relativePath: { eq: "ColosseumUI_Part1.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

      ColosseumUI_Part2: file(relativePath: { eq: "ColosseumUI_Part2.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }


  }
`